// Background.js
import React, { useState, useEffect } from 'react';
import corner1 from '../assets/UL.png'
import corner2 from '../assets/UR.png'
import corner3 from '../assets/LL.png'
import corner4 from '../assets/LR.png'
import middle1 from '../assets/UM.png'
import middle2 from '../assets/LM.png'
import middle3 from '../assets/DM.png'
import middle4 from '../assets/RM.png'
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import twitter from '../assets/twitter.png';
import telegram from '../assets/telegram.png';
import button from '../assets/button.png'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; // prevent horizontal scrolling
  }
`;

const Button = styled.button`
    position: fixed;
    background: transparent;
    border: none;
    z-index: 3; 
    background-repeat: no-repeat;
    background-size: contain;
    cursor: inherit;
    padding: 0;
`;

const Twitter = styled(Button)`
    background-image: url(${twitter});
    width: auto;
    height: calc(var(--vh, 1vh) * 6); // changed here
    bottom: ${props => props.clicked ? 'calc(var(--vh, 1vh) * 8.6)' : 'calc(var(--vh, 1vh) * 9.1)'}; // changed here
    aspect-ratio: 1;
    right: calc(var(--vh, 1vh) * 3.6); // changed here
    transition: bottom 0.3s ease, transform 0.3s ease;
    &:hover {
        transform: scale(1.1); /* adjust as needed */
    }
`;

const Telegram = styled(Button)`
    background-image: url(${telegram});
    width: auto;
    height: calc(var(--vh, 1vh) * 6); // changed here
    bottom: calc(var(--vh, 1vh) * 3.4); // changed here
    aspect-ratio: 1;
    right: calc(var(--vh, 1vh) * 9.45); // changed here
    transition: bottom 0.3s ease, transform 0.3s ease;
    &:hover {
        transform: scale(1.1); /* adjust as needed */
    }
`;

const MuteButton = styled(Button)`
    background-image: url(${button});
    width: auto;
    height: calc(var(--vh, 1vh) * 1.3); // changed here
    bottom: calc(var(--vh, 1vh) * 2.1); // changed here
    aspect-ratio: 3.8298;
    left: 50%;
    transform: translateX(-112%); /* adjust as needed */
    transition: bottom 0.3s ease, transform 0.3s ease;
    &:hover {
        transform: scale(1.04) translateX(-112%); /* adjust as needed */
    }
`;

const PlayButton = styled(Button)`
    background-image: url(${button});
    width: auto;
    height: calc(var(--vh, 1vh) * 1.3); // changed here
    bottom: calc(var(--vh, 1vh) * 2.2); // changed here
    aspect-ratio: 3.8298;
    left: 50%;
    transform: translateX(15%);
    transition: bottom 0.3s ease, transform 0.3s ease;
    &:hover {
        transform: scale(1.08) translateX(15%); /* adjust as needed */
    }
`;

const Corner1 = styled.div`
    position: fixed;
    width: auto; // adjust as needed
    height: 30vh; // automatically adjust the height based on the width
    background-size: cover; // scales the image to cover
    background-repeat: no-repeat; // prevents the image from repeating
    aspect-ratio: 0.673; // maintain a 0.673:1 aspect ratio
    z-index: 2; // corners will be on top
    pointer-events: none;
`;

const UL = styled(Corner1)`
    top: 0;
    left: 0;
    background-image: url(${corner1});
`;

const UR = styled(Corner1)`
    top: 0;
    right: 0;
    background-image: url(${corner2});
`;

const LL = styled(Corner1)`
    bottom: 0;
    left: 0;
    background-image: url(${corner3});
`;

const LR = styled(Corner1)`
    bottom: 0;
    right: 0;
    background-image: url(${corner4});
`;

const VerticalMiddle = styled.div`
    position: fixed;
    width: auto; // adjust as needed
    height: 150vh; // adjust to fill the viewport height
    background-size: cover; // scales the image to fit
    background-repeat: no-repeat; // prevents the image from repeating
    aspect-ratio: 0.03187; // maintain a 0.673:1 aspect ratio
    z-index: 1; 
    pointer-events: none;
`;

const HorizontalMiddleTop = styled.div`
    position: fixed;
    width: auto; // adjust to fill the viewport width
    height: 30vh; // adjust as needed
    background-size: cover; // scales the image to fit
    background-repeat: no-repeat; // prevents the image from repeating
    aspect-ratio: 10.256; // maintain a 0.673:1 aspect ratio
    z-index: 1; 
    pointer-events: none;
`;
const HorizontalMiddleBottom = styled.div`
    position: fixed;
    width: auto; // adjust to fill the viewport width
    height: 25vh; // adjust as needed
    background-size: cover; // scales the image to fit
    background-repeat: no-repeat; // prevents the image from repeating
    aspect-ratio: 12.337; // maintain a 0.673:1 aspect ratio
    z-index: 1; 
    pointer-events: none;
`;

const LM = styled(VerticalMiddle)`
    left: 0; // anchor to the left
    top: 50%; // move the top edge to the center
    transform: translateY(-50%); // shift it up by half its height
    background-image: url(${middle2});
`;

const UM = styled(HorizontalMiddleTop)`
    top: 0; // anchor to the top
    left: 50%; // move the left edge to the center
    transform: translateX(-50%); // shift it left by half its width
    background-image: url(${middle1});
`;

const DM = styled(HorizontalMiddleBottom)`
    bottom: 0; // anchor to the bottom
    left: 50%; // move the left edge to the center
    transform: translateX(-50%); // shift it left by half its width
    background-image: url(${middle3});
`;

const RM = styled(VerticalMiddle)`
    right: 0; // anchor to the right
    top: 50%; // move the top edge to the center
    transform: translateY(-50%); // shift it up by half its height
    background-image: url(${middle4});
`;

function Border({ muteAudio, playAudio }) {
    const [twitterClicked, setTwitterClicked] = useState(false);
    const [telegramClicked, setTelegramClicked] = useState(false);

    const handleTwitterClick = () => {
        setTwitterClicked(!twitterClicked);
        window.open('https://twitter.com/UPCOINWORLD', '_blank');
    };

    const handleTelegramClick = () => {
        setTelegramClicked(!telegramClicked);
        window.open('https://t.me/upcoinworld', '_blank');
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setTwitterClicked(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [twitterClicked]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTelegramClicked(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [telegramClicked]);

    return (
        <>
            <GlobalStyle />
            <UL />
            <UR />
            <LL />
            <LR />
            <LM />
            <RM />
            <DM />
            <UM />
            <Twitter onClick={handleTwitterClick} clicked={twitterClicked} />
            <Telegram onClick={handleTelegramClick} clicked={telegramClicked} />
            <MuteButton onClick={muteAudio} />
            <PlayButton onClick={playAudio} />
        </>
    );
}

export default Border;