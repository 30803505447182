import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import flavortext1 from './assets/flavortext1.png';
import flavortext2 from './assets/flavortext2.png';
import flavortext3 from './assets/flavortext3.png';
import flavortext4 from './assets/flavortext4.png';
import flavortext5 from './assets/flavortext5.png'
import cursor from './assets/select.cur';
import audioFile from './assets/music.wav';
import Border from './components/Border';
import audioFile1 from './assets/music1.wav';
import audioFile2 from './assets/music2.wav';
import audioFile3 from './assets/music3.wav';
import audioFile4 from './assets/music4.wav';

const Address = React.lazy(() => import('./components/Address')); 
const Checker = React.lazy(() => import('./components/Checker'));
const AboutPage = React.lazy(() => import('./components/AboutPage'));
const LandingPage = React.lazy(() => import('./components/LandingPage'));
const CommunityPage = React.lazy(() => import('./components/CommunityPage'));
const TokenPage = React.lazy(() => import('./components/TokenPage'));
const AirdropPage = React.lazy(() => import('./components/AirdropPage')); 

function App() {
  const audioRef = useRef(); // reference to audio element
  const [isPlaying, setIsPlaying] = useState(false); // state variable to keep track of whether audio is playing
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const parallaxRef = useRef();
  const [pages, setPages] = useState(window.innerWidth <= 768 ? 6.4 : 6);
  const isMobile = window.innerWidth <= 768;
  const audioFiles = [audioFile1, audioFile2, audioFile3, audioFile4];

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust this value as needed
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth;
      setPages(viewportWidth <= 768 ? 6.4 : 6);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const startAudio = () => {
    const randomIndex = Math.floor(Math.random() * audioFiles.length);
    audioRef.current.src = audioFiles[randomIndex];

    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const playAudio = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const muteAudio = (event) => {
    event.stopPropagation();
    audioRef.current.pause();
    setIsPlaying(false);
  };
  
  const handleScroll = () => {
    if (parallaxRef.current) {
      setScrollPosition(parallaxRef.current.current);
    }
  };

useEffect(() => {
    if (!isLoading) {
        const container = document.querySelector('.animation');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }
}, [isLoading]);

  useEffect(() => {
    if (!isLoading && parallaxRef.current) {
      parallaxRef.current.scrollTo(isMobile ? 4.7 : 4.5);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        Loading...
      </div>
    );
  }

  return (
    <div className="App" style={{ cursor: `url(${cursor}), auto`}} onClick={startAudio}>
      <audio ref={audioRef} src={audioFile} />
      <Parallax ref={parallaxRef} pages={pages} style={{ top: '0', left: '0', zIndex: 1 }} className="animation" key={pages}>
        <ParallaxLayer offset={0} speed={0.1}>
          <div className="animation_layer parallax" id="image1"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.4} speed={0.2}>
          <div clas444sName="animation_layer parallax"  id="image2"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.7} speed={0.3}>
          <div className="animation_layer parallax" id="image3"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.98} speed={0.4}>
          <div className="animation_layer parallax" id="image4"></div>
        </ParallaxLayer>
        <ParallaxLayer  offset={1} speed={0.5}>
          <div className="animation_layer parallax" id="image5"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.2} speed={0.6}>
          <div className="animation_layer parallax" id="image6"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.4} speed={0.7}>
          <div className="animation_layer parallax" id="image7"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.9} speed={0.8}>
          <div className="animation_layer parallax" id="image8"></div>
          <div style={{ position: 'fixed', top: isMobile ? '705vh' : '695vh', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <img src={flavortext1} alt="Flavor Text 1" style={{ imageRendering: 'pixelated' }} />
          </div>
          <div className="flavortext2">
            <img src={flavortext2} alt="Flavor Text 2" style={{ imageRendering: 'pixelated' }} />
            <p className="bouncing" style={{ color: 'black', fontFamily: 'Joystix', textAlign: 'center', marginTop: '20px' }}>&lt;SCROLL UP&gt;</p>
          </div>
          <div style={{ position: 'fixed', top: isMobile ? '420vh' : '420vh', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <p style={{ color: 'black', fontFamily: 'Joystix', textAlign: 'center', marginBottom: '20px' }}>UPDOG SAYS</p>
            <img src={flavortext3} alt="Flavor Text 3" style={{ imageRendering: 'pixelated' }} />
          </div>
          <div style={{ position: 'fixed', top: isMobile ? '190vh' : '160vh', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <img src={flavortext4} alt="Flavor Text 4" style={{ imageRendering: 'pixelated' }} />
          </div>
          <div className="Token">
            <p style={{ color: 'white', textAlign: 'center', fontSize: '8px' }}>Explorers. Dreamers. Adventurers. Innovators.</p>
            <p style={{ color: 'white', fontFamily: 'Joystix', textAlign: 'center', marginBottom: '20px' }}>Above The Moon.</p>
            <img src={flavortext5} alt="Flavor Text 1" style={{ imageRendering: 'pixelated', animation: 'spin 3s linear infinite', margin: '20px 0' }} />
            <p style={{ color: 'white', fontFamily: 'Joystix', textAlign: 'center', marginTop: '20px' }}>&lt;COMMUNITY&gt;</p>
          </div>
          <div className="Disclaimer">
            <p className="disclaimerText">$UP is simply a representation of the feelings towards life and its constant trajectory. $UP is a meme coin with no intrinsic value or expectation of financial return. The coin is completely useless and for entertainment purposes only.</p>
          </div>
          <div style={{ position: 'fixed', top: isMobile ? '660vh' : '670vh', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Checker />
          </div>
        </ParallaxLayer>
        
        <ParallaxLayer sticky={{ start: 0, end: 5 }} speed={0}>
          {((scrollPosition <= 4.5 * window.innerHeight && scrollPosition > 1.6 * window.innerHeight) || scrollPosition < 1.2 * window.innerHeight) && (
            <CommunityPage />
          )}
          <TransitionGroup>
            {scrollPosition < 1.2 * window.innerHeight && (
              <CSSTransition timeout={500} classNames="fade">
                <AboutPage />
              </CSSTransition>
            )}
            {scrollPosition >= 1.6 * window.innerHeight && scrollPosition < 2.5 * window.innerHeight && (
              <CSSTransition timeout={500} classNames="fade">
                <LandingPage />
              </CSSTransition>
            )}
            {scrollPosition >= 2.5 * window.innerHeight && scrollPosition < 3.5 * window.innerHeight && (
              <CSSTransition timeout={500} classNames="fade">
                <AirdropPage />
              </CSSTransition>
            )}
            {/* {scrollPosition >= 2.5 * window.innerHeight && scrollPosition <= 3.5 * window.innerHeight && (
              <CSSTransition timeout={500} classNames="fade">
                <Address />
              </CSSTransition>
            )} */}
            {scrollPosition >= 3.5 * window.innerHeight && scrollPosition <= 4.5 * window.innerHeight && (
              <CSSTransition timeout={500} classNames="fade">
                <TokenPage />
              </CSSTransition>
            )}  
          </TransitionGroup>
        </ParallaxLayer>
      </Parallax>
      <Border playAudio={playAudio} muteAudio={muteAudio} style={{ zIndex: 3 }} />
    </div>
  );
}

export default App;